export const initTranslate = (): (() => void) => {
    const gTranslateScript = document.createElement("script");
    gTranslateScript.async = true;
    gTranslateScript.type = "text/javascript";
    gTranslateScript.src =
      "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
  
    const gElementInit = document.createElement("script");
    gElementInit.type = "text/javascript";
    gElementInit.textContent = `function googleTranslateElementInit() {
        new google.translate.TranslateElement({
          pageLanguage: 'en',
          layout: google.translate.TranslateElement.InlineLayout.VERTICAL,
          autoDisplay: false,
          includedLanguages: 'ar,zh-CN,en,fr,ht,ko,pl,ru,es,vi'
        }, 'google_translate_element');
      }`;
      // layout: google.translate.TranslateElement.InlineLayout.VERTICAL,
    document.body.appendChild(gTranslateScript);
    document.body.appendChild(gElementInit);
  
    return (): void => {
      gTranslateScript.remove();
      gElementInit.remove();
    };
  };
  