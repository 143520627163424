// #region import
import { useEffect, useMemo, useState,useContext} from "react";
import { useBoolean } from "@fluentui/react-hooks"
import { FontIcon, Stack, Text,IconButton} from "@fluentui/react";
import { CopyRegular } from "@fluentui/react-icons";
import styles from "./Answer.module.css";
import Plot from 'react-plotly.js'
import { AskResponse, Citation, FeedbackEnum, historyMessageFeedback, messageStatus, ChatMessage} from "../../api";
import { parseAnswer } from "./AnswerParser";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import supersub from 'remark-supersub'
import classnames from 'classnames'
import { SpeechOutput } from "./SpeechOutput";
import { AppStateContext } from "../../state/AppProvider";
import DOMPurify from 'dompurify';
import { XSSAllowTags, XSSAllowAttributes } from "../../constants/sanitizeAllowables";
import debounce from 'debounce';
// #endregion

interface Props {
    answer: AskResponse;
    onCitationClicked: (citedDocument: Citation) => void;
    isLoading?: boolean;
   // isStreamComplete?: boolean;
    isDone?: boolean;
    msgIndex?:number;
    isMsgRenderComplete: boolean;
    setIsMsgRenderComplete: (isMsgRenderComplete: boolean) => void;
    darkmode: boolean;
    pgm:string;
    onExecResultClicked: () => void; 
    speechUrl: string | null;
    isMsgPlaying: boolean;
    setIsMsgPlaying: (isMsgPLaying: boolean) => void;
    isNewConv:boolean;
    stopMsgPlaying :boolean;
    onFollowupQuestionClicked?: (question: string) => void;
    showFollowupQuestions?: boolean;
}
// #region const
 
 
// const sessionStorage = new SessionStorage();
export const Answer = ({ answer, onCitationClicked, isLoading, isDone, msgIndex, isMsgRenderComplete, setIsMsgRenderComplete, 
    darkmode, pgm, onExecResultClicked, speechUrl, isMsgPlaying, setIsMsgPlaying, isNewConv, stopMsgPlaying, onFollowupQuestionClicked,
    showFollowupQuestions
}: Props) => {
    let chatUUID='';
    let  msgId='';
    let ansClass = classnames(styles.answerText, "notranslate");
    const followupQuestions = answer.followup_q?.followup_questions;

    
    // if (followupQuestions !== undefined) {
    //     console.log("*****************" + followupQuestions)
    // }
    const initializeAnswerFeedback = (answer: AskResponse) => {
        /*console.log("msgid");
        console.log(answer.message_id);
        console.log(answer.msgStatus);*/
        if (answer.message_id == undefined) {
            return undefined;
        }
        else {
            /*if (answer.feedbackOnMsg == undefined) return undefined;*/
            if (answer.feedbackOnMsg == undefined) {
                return FeedbackEnum.Neutral;
            }
            if (answer.feedbackOnMsg == FeedbackEnum.Positive || answer.feedbackOnMsg == FeedbackEnum.Negative || answer.feedbackOnMsg == FeedbackEnum.Neutral) {
                return answer.feedbackOnMsg;
            }
            else {
                return FeedbackEnum.Negative;
            }
        }
       
    }
    const appStateContext = useContext(AppStateContext)
    const [isRefAccordionOpen, { toggle: toggleIsRefAccordionOpen }] = useBoolean(true);
    const [isFollowUpAccordionOpen, { toggle: toggleIsFollowUpAccordionOpen }] = useBoolean(true);
    const filePathTruncationLimit = 50;
    const [feedbackState, setFeedbackState] = useState(initializeAnswerFeedback(answer));
    // const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState(false);
    //const [showReportInappropriateFeedback, setShowReportInappropriateFeedback] = useState(false);
    //const [negativeFeedbackList, setNegativeFeedbackList] = useState<FeedbackEnum[]>([]);
    const [hoveredLike, setHoveredLike] = useState(false);
    const [hoveredDislike, setHoveredDislike] = useState(false);
    const [messages, setMessages] = useState<ChatMessage[]>([])
    const branding = appStateContext?.state.frontendSettings?.branding;
    // const [text, setText] = useState<string>("");
    const [currenttime, setCurrentTime] = useState<number>(0);
    // const [thisMsgIndex, setThisMsgIndex] = useState<number>(msgIndex? msgIndex : 0);
    const [thisMsgIsPlaying, setThisMsgIsPlaying] = useState<boolean>(false);
    const msgFBEnabled = () => {
       return answer.feedbackOnMsg !=undefined && !isWriting && answer.message_id != undefined && answer.msgStatus == messageStatus.NotRunning  
    }
    const handleLikeMouseEnter = () => {
        if (msgFBEnabled()) { setHoveredLike(true);}
    };

    const handleLikeMouseLeave = () => {
        if (msgFBEnabled()) { setHoveredLike(false);}
    };
    const handleDislikeMouseEnter = () => {
        if (msgFBEnabled()) {  setHoveredDislike(true);}
    };

    const handleDislikeMouseLeave = () => {
        if (msgFBEnabled ()) { setHoveredDislike(false);}
    };
    let hideCitations:string = "false";
    // if (appStateContext?.state.showHideCitations?.hideCitations){
    //     hideCitations =appStateContext?.state.showHideCitations?.hideCitations
    // } ;
    if (appStateContext?.state.frontendSettings?.branding){
        hideCitations = appStateContext?.state.frontendSettings?.branding.citation_disabled
    };
     
    let showMsgFb:string = "false";
    // if (appStateContext?.state.showHideMsgFb?.showMsgFb){
    //     showMsgFb =appStateContext?.state.showHideMsgFb?.showMsgFb
    // };
    if (appStateContext?.state.frontendSettings?.branding) {
        showMsgFb = appStateContext?.state.frontendSettings?.branding.msg_feedback_enabled
    };


     
    const parsedAnswer = useMemo(() => parseAnswer(answer,hideCitations), [answer]);
    const markDownText = DOMPurify.sanitize(parsedAnswer.markdownFormatText, { ALLOWED_TAGS: XSSAllowTags, ALLOWED_ATTR: XSSAllowAttributes });
 
    const [renderedText, setRenderedText] = useState("");
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isWriting, setIsWriting] = useState(false);
    const [stopMsg,setStopMsg]= useState<boolean>(false);
    const [chevronIsExpanded, setChevronIsExpanded] = useState(isRefAccordionOpen);
    const [chevronFollowUpIsExpanded, setChevronFollowUpIsExpanded] = useState(isFollowUpAccordionOpen);
    const handleChevronClick = () => {
        setChevronIsExpanded(!chevronIsExpanded);
        toggleIsRefAccordionOpen();
      };
    const handleChevronFollowUpClick = () => {
        setChevronFollowUpIsExpanded(!chevronFollowUpIsExpanded);
        toggleIsFollowUpAccordionOpen();
    };
    useEffect(() => {
        setChevronIsExpanded(isRefAccordionOpen);
    }, [isRefAccordionOpen]);
    useEffect(() => {
        setChevronFollowUpIsExpanded(isFollowUpAccordionOpen);
    }, [isFollowUpAccordionOpen]);
    useEffect(() => {
       
        if (isNewConv) {
            //setStopMsg(true);
           // console.log("tMI " + thisMsgIndex + " CT " + currenttime + " new conv " + isNewConv + thisMsgIsPlaying + isMsgPlaying)
            //console.log("**New conv going to set current time to 0")
            setCurrentTime(0);
        } 
         
 
    }, [isNewConv]);
    useEffect(() => {

      //console.log("**stopMsgPlaying " + stopMsgPlaying);
      setStopMsg(stopMsgPlaying);

    }, [stopMsgPlaying]);
    useEffect(() => {
        const renderTextGradually = (): void => {
          const textToRender = markDownText.length>0? markDownText : "Generating answer...";
          const cursor = "&#9646;";
          let runIt = "T";
       
          if (textToRender === "Generating answer..."){
            setIsMsgRenderComplete(false);
            setRenderedText(textToRender + cursor);
           
          }
          else
          {
            if (!isDone && msgIndex === 2){
                runIt = "F"
                
            }
            if (isDone && msgIndex === 2){
                runIt = "T"
            }
            if (runIt ==  "T") {
                if ((currentIndex < textToRender.length) ) {
                    setRenderedText(textToRender.substring(0, currentIndex + 1) + cursor);
                    setCurrentIndex(currentIndex + 1);
                    setIsWriting(true);
                
                } else {
                    if (!isDone){
                             setRenderedText(textToRender.substring(0, currentIndex + 1) + cursor)
                     }
                     else {
                            setRenderedText(textToRender.substring(0, currentIndex + 1) + "");
                    }
                    
                    setIsWriting(false);
                    
                }
                 } 
                 else {
                     setRenderedText("Generating answer..." + cursor);
                     setIsMsgRenderComplete(false);
                 }
            }
         }
        const intervalId = setInterval(renderTextGradually, 10);
        //console.log(thisMsgIndex + ">>" + msgIndex)
        
        return () => {
          clearInterval(intervalId);
            //console.log("Ans " + currentIndex + ' > ' + markDownText.length + ' >> ' + isDone)
            if ((currentIndex + 1 === markDownText.length || currentIndex === markDownText.length) && isDone ){
            //console.log("comes to set msg render to true")
            // //     console.log(appStateContext?.state.isAnswerComplete);
            // //     if (!appStateContext?.state.isAnswerComplete){
            // //         console.log("going to toggle from answer from " +  appStateContext?.state.isAnswerComplete + " to " + !appStateContext?.state.isAnswerComplete);
            // //         appStateContext?.dispatch({ type: 'TOGGLE_ANSWER_COMPLETE' })
            // //     }  
                  setIsMsgRenderComplete(true);
                 

         }
     
        };
      }, [markDownText, currentIndex]);
    const createCitationFilepath = (citation: Citation, index: number, truncate: boolean = false) => {
        let citationFilename = "";

        if (citation.filepath) {
            const part_i = citation.part_index ?? (citation.chunk_id ? parseInt(citation.chunk_id) + 1 : '');
            if (truncate && citation.filepath.length > filePathTruncationLimit) {
                const citationLength = citation.filepath.length;
                citationFilename = `${citation.filepath.substring(0, 20)}...${citation.filepath.substring(citationLength - 20)} - Part ${part_i}`;
            }
            else {
                citationFilename = `${citation.filepath} - Part ${part_i}`;
            }
        }
        else if (citation.filepath && citation.reindex_id) {
            citationFilename = `${citation.filepath} - Part ${citation.reindex_id}`;
        }
        else {
            citationFilename = `Citation ${index}`;
        }
        return citationFilename;
    }
    const retrieveChatUUID = () => {
        chatUUID = sessionStorage.sessionUUID;
      
    }
    const setMsgID = (msgId: string) => {
        sessionStorage.msgID = msgId;
        
    }
  
    const setMsgFB = (msgFB: string) => {
        sessionStorage.msgFB = msgFB;
    }
    const updateMsg = (msgId: string, newFeedbackState:FeedbackEnum) => {
        let conv = appStateContext?.state.currentChat ||  null
        let msg =  conv?.messages
        if (msg){
            msg?.forEach( p => {
                if (p.id === msgId){
                    p.feedbackOnMsg = newFeedbackState
                }
            })
            appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conv });
            setMessages(msg)
        }
    }
   
    const onLikeResponseClicked = async () => {
        setStopMsg(true);
         
        if (answer.message_id == undefined) {
            appStateContext?.dispatch({ type: 'MSGFB', payload: 'N' })
            setMsgID("");
            setMsgFB("neutral")
            return;
        }
        setMsgID(answer.message_id)
        appStateContext?.dispatch({ type: 'MSGFB', payload: 'Y' })
       
        let convId:string = "";
        if (appStateContext?.state.currentChat?.id){
            convId =appStateContext?.state.currentChat?.id
  
        };
        retrieveChatUUID();
        let newFeedbackState = feedbackState;
         
        if (feedbackState == FeedbackEnum.Positive) {
            // from positive to neutral - record only
            newFeedbackState = FeedbackEnum.Neutral;
            setFeedbackState(newFeedbackState);
            setMsgFB("neutral")
            let response = await historyMessageFeedback(answer.message_id, newFeedbackState, convId, chatUUID, pgm);
            if (response.ok){
                updateMsg(answer.message_id, newFeedbackState)
              
            }
        }
        else {
            // from neutral to positive - record & show dialog
            newFeedbackState = FeedbackEnum.Positive;
            setFeedbackState(newFeedbackState);
            setMsgFB("positive")
            let response = await historyMessageFeedback(answer.message_id, newFeedbackState, convId, chatUUID, pgm);
            // setIsFeedbackDialogOpen(true);
            // setTimeout(() => setText(" "),100);
            // if (response.ok){
                updateMsg(answer.message_id, newFeedbackState)
                 
            // }
            appStateContext?.dispatch({ type: 'TOGGLE_FEEDBACK_PANEL' })
        }
        setStopMsg(false);
    }
     
    const onDislikeResponseClicked = async () => {
        setStopMsg(true);
        if (answer.message_id == undefined) {
            setMsgID("")
            appStateContext?.dispatch({ type: 'MSGFB', payload: 'N' })
            setMsgFB("neutral")
            return;
        }
        
        setMsgID(answer.message_id)
        appStateContext?.dispatch({ type: 'MSGFB', payload: 'Y' })
        let newFeedbackState = feedbackState;
        let convId:string = "";
      
        if (appStateContext?.state.currentChat?.id){
            convId =appStateContext?.state.currentChat?.id
          
        };
        retrieveChatUUID();
        if (feedbackState == FeedbackEnum.Negative) {
            // from negative to neutral - record only
            newFeedbackState = FeedbackEnum.Neutral;
            setFeedbackState(newFeedbackState);
            setMsgFB("neutral")
            let response = await historyMessageFeedback(answer.message_id, newFeedbackState, convId, chatUUID, pgm);
            if (response.ok){
                updateMsg(answer.message_id, newFeedbackState)
            }
        }
        else {
            // from neutral to negative - record & show dialog
            newFeedbackState = FeedbackEnum.Negative;
            setFeedbackState(newFeedbackState);
            setMsgFB("negative")
            let response = await historyMessageFeedback(answer.message_id, newFeedbackState, convId, chatUUID, pgm);
            if (response.ok){
                updateMsg(answer.message_id, newFeedbackState)
                     
            }
            // setIsFeedbackDialogOpen(true);
            // setTimeout(() => setText(" "),100);
            appStateContext?.dispatch({ type: 'TOGGLE_FEEDBACK_PANEL' })
        }
        setStopMsg(false);
    }
    const onLikeResponse = debounce(onLikeResponseClicked, 300) 
    const onDislikeResponse = debounce(onDislikeResponseClicked, 300)
    const [copyStatus, setCopyStatus] = useState(false);
    const copyToClipboard = (msg: string) => {
        navigator.clipboard.writeText(msg);
        setCopyStatus(true);
        setTimeout(() => setCopyStatus(false), 2000);
    };
    // const handleFeedbackModalClick = () => {
    //     setIsFeedbackDialogOpen(false);
    //     appStateContext?.dispatch({ type: 'TOGGLE_FEEDBACK_PANEL' })
    // } 
   
    // const resetFeedbackDialog = () => {
    //     setText("");
    //     setIsFeedbackDialogOpen(false);
    //     setMsgID("");
    //     setMsgFB("neutral");
    //     /*setShowReportInappropriateFeedback(false);
    //     setNegativeFeedbackList([]);*/
    // }
    // const updateFeedbackList = (ev?: FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
    //     if (answer.message_id == undefined) return;
    //     let selectedFeedback = (ev?.target as HTMLInputElement)?.id as Feedback;

    //     let feedbackList = negativeFeedbackList.slice();
    //     if (checked) {
    //         feedbackList.push(selectedFeedback);
    //     } else {
    //         feedbackList = feedbackList.filter((f) => f !== selectedFeedback);
    //     }

    //     setNegativeFeedbackList(feedbackList);
    // };

    // const onSubmitNegativeFeedback = async () => {
    //     if (answer.message_id == undefined) return;
    //     let convId:string = "";
    //     if (appStateContext?.state.currentChat?.id){
    //         convId =appStateContext?.state.currentChat?.id
 
    //     };
    //     retrieveChatUUID()
    //     await historyMessageFeedback(answer.message_id, negativeFeedbackList.join(","), convId, chatUUID);
    //     resetFeedbackDialog();
    // }
    // const UnhelpfulFeedbackContent = () => {
    //     return (<>
    //         <div>Why wasn't this response helpful?</div>
    //         <Stack tokens={{childrenGap: 4}}>
    //             <Checkbox label="Citations are missing" id={FeedbackEnum.MissingCitation} defaultChecked={negativeFeedbackList.includes(FeedbackEnum.MissingCitation)} onChange={updateFeedbackList}></Checkbox>
    //             <Checkbox label="Citations are wrong" id={FeedbackEnum.WrongCitation} defaultChecked={negativeFeedbackList.includes(FeedbackEnum.WrongCitation)} onChange={updateFeedbackList}></Checkbox>
    //             <Checkbox label="The response is not from my data" id={FeedbackEnum.OutOfScope} defaultChecked={negativeFeedbackList.includes(FeedbackEnum.OutOfScope)} onChange={updateFeedbackList}></Checkbox>
    //             <Checkbox label="Inaccurate or irrelevant" id={FeedbackEnum.InaccurateOrIrrelevant} defaultChecked={negativeFeedbackList.includes(FeedbackEnum.InaccurateOrIrrelevant)} onChange={updateFeedbackList}></Checkbox>
    //             <Checkbox label="Other" id={FeedbackEnum.OtherUnhelpful} defaultChecked={negativeFeedbackList.includes(FeedbackEnum.OtherUnhelpful)} onChange={updateFeedbackList}></Checkbox>
    //         </Stack>
    //         <div onClick={() => setShowReportInappropriateFeedback(true)} style={{ color: "#115EA3", cursor: "pointer"}}>Report inappropriate content</div>
    //     </>);
    // }

    // const ReportInappropriateFeedbackContent = () => {
    //     return (
    //         <>
    //             <div>The content is <span style={{ color: "red" }} >*</span></div>
    //             <Stack tokens={{childrenGap: 4}}>
    //                 <Checkbox label="Hate speech, stereotyping, demeaning" id={FeedbackEnum.HateSpeech} defaultChecked={negativeFeedbackList.includes(FeedbackEnum.HateSpeech)} onChange={updateFeedbackList}></Checkbox>
    //                 <Checkbox label="Violent: glorification of violence, self-harm" id={FeedbackEnum.Violent} defaultChecked={negativeFeedbackList.includes(FeedbackEnum.Violent)} onChange={updateFeedbackList}></Checkbox>
    //                 <Checkbox label="Sexual: explicit content, grooming" id={FeedbackEnum.Sexual} defaultChecked={negativeFeedbackList.includes(FeedbackEnum.Sexual)} onChange={updateFeedbackList}></Checkbox>
    //                 <Checkbox label="Manipulative: devious, emotional, pushy, bullying" defaultChecked={negativeFeedbackList.includes(FeedbackEnum.Manipulative)} id={FeedbackEnum.Manipulative} onChange={updateFeedbackList}></Checkbox>
    //                 <Checkbox label="Other" id={FeedbackEnum.OtherHarmful} defaultChecked={negativeFeedbackList.includes(FeedbackEnum.OtherHarmful)} onChange={updateFeedbackList}></Checkbox>
    //             </Stack>
    //         </>
    //     );
    // }
// #endregion  
    return (
        <>
            <Stack className={darkmode ? styles.answerContainerDM : styles.answerContainer} tabIndex={0}  grow>
            <Stack horizontal horizontalAlign="start" className={styles.answerContainerInt1} grow>
                <Stack horizontal horizontalAlign="start">
                    <img
                        src={darkmode ? branding?.msg_icon_darkmode : branding?.msg_icon}
                        className={styles.msgIcon}
                        aria-hidden="true"
                        alt="Message Icon"
                    />
                </Stack>

                <Stack className={styles.answerContainerInt2} horizontal  grow>
                              
                    <Stack.Item  grow>
                    <Stack horizontal grow className={styles.answerContainerInt3}>
                        <Stack.Item grow>
                            <div>   
                                <Stack horizontal horizontalAlign="space-between" className={styles.answerContainerInt4}>
                                    <Stack horizontalAlign="start" className={styles.msgTimeA}>{new Date(answer.date).toLocaleTimeString([], {hour: 'numeric', minute: '2-digit', hour12: true})}</Stack>
                                            <Stack horizontalAlign="end" horizontal verticalAlign="center">
                                        <div   style={{ cursor: isMsgRenderComplete ? "pointer" : "" }} onClick={() => isMsgRenderComplete ? copyToClipboard(renderedText) : ""} aria-label="Copy" title="Copy">
                                                    <CopyRegular role="button" tabIndex={0} className={styles.errorIcon} style={{ color: darkmode ? 'white' : 'black', height: '22px', width: '22px',outline: 'none' }} aria-label="Copy"   title="Copy" />
                                                    {/* <img role="button" tabIndex={0} src={Copy16Regular} aria-label="Copy" alt="Copy" title="Copy" style={{ color: darkmode? 'white': 'black', backgroundColor: "transparent" }} />*/}
                                        </div> 
                                                {branding?.text_to_speech_enabled === "Y" &&
                                                    <div>
                                                    {/* <SpeechOutput url={isMsgRenderComplete ? speechUrl : null} darkmode={darkmode} playbackrate={branding?.text_to_speech_playbackrate}
                                                        msgCurrentTime={msgCurrentTime} setMsgCurrentTime={setMsgCurrentTime}
                                                        isMsgPlaying={isMsgPlaying} setIsMsgPlaying={setIsMsgPlaying}
                                                        thisMsgIsPlaying={thisMsgIsPlaying} setThisMsgIsPlaying={setThisMsgIsPlaying}
                                                        thisMsgIndex={thisMsgIndex} isMsgPlayPaused={isMsgPlayPaused} setIsMsgPlayPaused={setIsMsgPlayPaused}
                                                         isNewConv={isNewConv}   
                                                    /> */}
                                                        <SpeechOutput url={isMsgRenderComplete ? speechUrl : null} darkmode={darkmode} playbackrate={branding?.text_to_speech_playbackrate}
                                                            currenttime={currenttime} setCurrentTime={setCurrentTime}
                                                            isMsgPlaying={isMsgPlaying} setIsMsgPlaying={setIsMsgPlaying}
                                                            thisMsgIsPlaying={thisMsgIsPlaying} setThisMsgIsPlaying={setThisMsgIsPlaying}
                                                            stopMsgPlaying={stopMsg}
                                                           
                                                             
                                                        />
                                                    </div>
                                                } 
                                    </Stack>
                                </Stack>
                            </div>
                            
                            <ReactMarkdown 
                                linkTarget="_blank"
                                remarkPlugins={[remarkGfm, supersub]}
                                // children={parsedAnswer.markdownFormatText}
                                children={renderedText}
                                //children={DOMPurify.sanitize(parsedAnswer.markdownFormatText, {ALLOWED_TAGS: XSSAllowTags})}
                                className={ansClass}
                            />
                        </Stack.Item>
                     
                    </Stack>
                    </Stack.Item>
                </Stack>
               
               
               {/* {isWriting && (
                    <div style={{ visibility: "hidden",  position: "absolute" }}>
                        Generating answer...
                    </div>
               )}  */}
              
            </Stack>
            {parsedAnswer.plotly_data !== null && (
                <Stack className={styles.answerContainer}>
                    <Stack.Item grow>
                        <Plot data={parsedAnswer.plotly_data.data} layout={parsedAnswer.plotly_data.layout} />
                    </Stack.Item>
                </Stack>
            )}
            <Stack horizontal className={styles.answerFooter}>
                    {!!parsedAnswer.citations.length && (
                        <Stack.Item onKeyDown={e => e.key === "Enter" || e.key === " " ? toggleIsRefAccordionOpen() : null}>
                            <Stack style={{width: "100%"}} >
                                <Stack horizontal horizontalAlign='start' verticalAlign='center'>
                                    <Text
                                        className={styles.accordionTitle}
                                        onClick={toggleIsRefAccordionOpen}
                                        aria-label="Open/Close Citation(s)"
                                        tabIndex={0}
                                        role="button"
                                        title="Open/Close Citation(s)"
                                    >
                                    <span className={styles.citationHeader}>{parsedAnswer.citations.length > 1 ? "Citations:" : "Citation:"}</span>
                                    </Text> 
                                    <FontIcon className={styles.accordionIcon}
                                    onClick={handleChevronClick} iconName={chevronIsExpanded ? 'ChevronUp' : 'ChevronDown'}
                                    />
                                </Stack>
                                
                            </Stack>
                        </Stack.Item>
                    )}
                    {!!answer.exec_results?.length && (
                        <Stack.Item onKeyDown={e => (e.key === 'Enter' || e.key === ' ' ? toggleIsRefAccordionOpen() : null)}>
                            <Stack style={{ width: '100%' }}>
                                <Stack horizontal horizontalAlign="start" verticalAlign="center">
                                    <Text
                                        className={styles.accordionTitle}
                                        onClick={() => onExecResultClicked()}
                                        aria-label="Open Intents"
                                        tabIndex={0}
                                        role="button">
                                        <span>
                                            Show Intents
                                        </span>
                                    </Text>
                                    <FontIcon
                                        className={styles.accordionIcon}
                                        onClick={handleChevronClick}
                                        iconName={'ChevronRight'}
                                    />
                                </Stack>
                            </Stack>
                        </Stack.Item>
                    )}
                    </Stack>
                 {chevronIsExpanded &&  (
                  
                        <div style={{ marginLeft: 47, display: "flex", flexFlow: "wrap column",  gap: "4px", width: window.innerWidth <=768 ? '80%' : '93%' }}>
                            {parsedAnswer.citations.map((citation, idx) => {
                                return (
                                    <span 
                                        title={createCitationFilepath(citation, ++idx)} 
                                        tabIndex={0} 
                                        role="link" 
                                        key={idx} 
                                        onClick={() => isMsgRenderComplete ? onCitationClicked(citation) : null} 
                                        onKeyDown={e => isMsgRenderComplete && (e.key === "Enter" || e.key === " ") ? onCitationClicked(citation) : null}
                                        className={styles.citationContainer}
                                        aria-label={createCitationFilepath(citation, idx)}
                                    >
                                        <div className={styles.citation}>{idx + '.'}</div>
                                        {createCitationFilepath(citation, idx, true)}
                                    </span>);
                            })}
                        </div>
                      )}  
                {!!followupQuestions?.length && showFollowupQuestions && onFollowupQuestionClicked && (
                    <Stack horizontal className={styles.answerFooter}>
                        <Stack.Item onKeyDown={e => e.key === "Enter" || e.key === " " ? toggleIsFollowUpAccordionOpen() : null}>
                            <Stack style={{width: "100%"}} >
                                <Stack horizontal horizontalAlign='start' verticalAlign='center' style={{ paddingTop: '5px' }}>
                                    <Text
                                        className={styles.accordionTitle}
                                        onClick={toggleIsFollowUpAccordionOpen}
                                        aria-label={answer.isCopilotResponse === "N" ? "Open/Close Related Questions:" : "Open/Close to Select Response"}
                                        tabIndex={0}
                                        role="button"
                                        title={answer.isCopilotResponse === "N" ? "Open/Close Related Questions:" : "Open/Close to Select Response"}
                                    >
                                    <span className={styles.citationHeader}>{answer.isCopilotResponse === "N"? "Related Questions:" : "Select Response"}</span>
                                    </Text>
                                    <FontIcon className={styles.accordionIcon}
                                        onClick={handleChevronFollowUpClick} iconName={chevronFollowUpIsExpanded ? 'ChevronUp' : 'ChevronDown'}
                                    />
                                </Stack>
                           </Stack>
                    </Stack.Item>
                    </Stack>
                )}

                {!!followupQuestions?.length && showFollowupQuestions && onFollowupQuestionClicked && chevronFollowUpIsExpanded && (
                    <div style={{ marginLeft: 47, display: "flex", flexFlow: "wrap column", gap: "4px", width: window.innerWidth <= 768 ? '80%' : '93%' }}>
                        {followupQuestions.map((x, i) => {
                            return (
                                
                                <span 
                                        title={x} 
                                        tabIndex={0} 
                                        role="link" 
                                        key={i} 
                                        onClick={() => isMsgRenderComplete ? onFollowupQuestionClicked(x) : null} 
                                        onKeyDown={e => isMsgRenderComplete && (e.key === "Enter" || e.key === " ") ? onFollowupQuestionClicked(x) : null}
                                        className={styles.followupContainer}
                                        aria-label={x}
                                    >
                                        {/* <div className={styles.citation}>{i+1 + '.'}</div> */}
                                        {x}
                                    </span>
                            );
                        })}
                    </div>
                )}
                <div style={{ width: window.innerWidth <= 768 ? '80%' : '93%', marginTop: "10px", height: "32px" ,marginLeft: 47, }}> 
                        {showMsgFb == "true"   && 
                        <Stack horizontal horizontalAlign="baseline" className={styles.feedbackDiv}>
                            <Stack horizontal verticalAlign="center" horizontalAlign="space-between" style={{ width: "100%"}}>
                                <Stack horizontal verticalAlign="center" horizontalAlign="start" className={styles.feedbackQ}>
                                    <div >Was this response helpful?</div>
                                </Stack>
                                <Stack horizontal horizontalAlign="end" verticalAlign="center">
                                    <IconButton  
                                        aria-hidden="false"
                                        aria-label="Like this response"
                                        title="Like this response"
                                        iconProps={hoveredLike || answer.feedbackOnMsg == FeedbackEnum.Positive ?
                                            { iconName: 'LikeSolid', className: msgFBEnabled() ? styles.likeIcon : styles.likeIconDisabled } : { iconName: 'Like', className: msgFBEnabled() ? styles.likeIcon : styles.likeIconDisabled }}
                                        onMouseEnter={msgFBEnabled() ? handleLikeMouseEnter : void (0)}
                                        onMouseLeave={msgFBEnabled() ? handleLikeMouseLeave : void (0)}
                                        onClick={() => msgFBEnabled() ? onLikeResponse() : null}
                                        styles={{
                                            root: { margin: '0px', padding: '0px', backgroundColor: 'transparent' },
                                            rootHovered: { margin: '0px', padding: '0px', backgroundColor: 'transparent' },
                                            rootPressed: { margin: '0px', padding: '0px', backgroundColor: 'transparent' },
                                        }}
                                    /> <div className={styles.feedbackQYN}>Yes</div>
                                    <IconButton
                                        aria-hidden="false"
                                        aria-label="Dislike this response"
                                        title="Dislike this response"
                                        iconProps={hoveredDislike || answer.feedbackOnMsg == FeedbackEnum.Negative ?
                                            { iconName: 'DislikeSolid', className: msgFBEnabled() ? styles.likeIcon : styles.likeIconDisabled } : { iconName: 'Dislike', className: msgFBEnabled() ? styles.likeIcon : styles.likeIconDisabled }}
                                        onMouseEnter={msgFBEnabled() ? handleDislikeMouseEnter : void (0)}
                                        onMouseLeave={msgFBEnabled() ? handleDislikeMouseLeave : void (0)}
                                        onClick={() => msgFBEnabled() ? onDislikeResponse() : null}
                                        styles={{
                                            root: { margin: '0px', padding: '0px', backgroundColor: 'transparent', },
                                            rootHovered: { margin: '0px', padding: '0px', backgroundColor: 'transparent', },
                                            rootPressed: { margin: '0px', padding: '0px', backgroundColor: 'transparent', },
                                        }}
                                    /> <div className={styles.feedbackQYN}>No</div>
                                </Stack>

                            </Stack>
                        </Stack> 
                                 
                        }
                     </div>
                </Stack>
            {copyStatus && <p style={{ color: darkmode ? "#FFFFFF" : "#000000" }}>Text copied to clipboard!</p>}
            {/* <Dialog 
             
                onDismiss={() => {
                    resetFeedbackDialog();
                    
                }}
                hidden={!isFeedbackDialogOpen}
                styles={{

                    main: [{
                        selectors: {
                          ['@media (min-width: 280px)']: {
                            maxWidth: '80%',background: "#FFFFFF",
                            boxShadow: '0px 2px 20px -1px rgba(0, 0, 0, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
                            borderRadius: "0px", maxHeight: '80%', minHeight: '100px',minWidth: '200px',padding: '0px',flexDirection: 'column',
                            alignItems: 'flex-start', gap: '0px',
                          }
                        }
                      }]
                }}
                dialogContentProps={{ title: " ", showCloseButton:true }}
            >
             <Stack horizontal verticalAlign="center">
                   <div>
                        <Stack className={styles.modalContainer}>
                           
                              <Stack horizontal verticalAlign="center">
                                    <Stack horizontalAlign="start" verticalAlign="center">    
                                        <img  src={BannerTopRight} aria-hidden="true"  className={styles.modalIcon}/></Stack>
                                    <Stack horizontalAlign="space-between" verticalAlign="center" className={styles.subtitleModalContainer}>
                                            <b className={styles.modalEmptyStateSubtitle}>Submit Feedback</b></Stack>
                                    
                              </Stack>
                                <hr  style={{background: "silver", width: "100%", height: "1px"}}/> 
                                                                                             
                                <br/>Your feedback is important to us! Would you like to submit additional information on the answer provided?
                                <input type="text" value={text} contentEditable={false}  style={{ borderStyle: 'none', width: '0px', height: '0px', borderColor: 'white', color: 'white', border: '0px'}}/>
                                
                        </Stack>
                        <Stack horizontal horizontalAlign="center">  
                            <CommandBarButton tabIndex={0}
                                role="button"
                                className={styles.closeButton}
                                onClick={handleFeedbackModalClick}
                                text="YES"
                                aria-label="Yes"
                                title="Yes"/> 
                            <CommandBarButton
                                role="button"
                                className={styles.noButton}
                                onClick={resetFeedbackDialog}
                                text="NO"
                                aria-label="No"
                                title="No"/>
                        </Stack>
                    </div>
                </Stack>
               
            </Dialog> */}
        </>
    );
};
