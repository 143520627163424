import { useState,useEffect } from "react";
import { requestTTS } from "../../api";
import { IconButton } from "@fluentui/react";

interface Props {
    url: string | null;
    darkmode: boolean;
    playbackrate: string | null;
    currenttime: number;
    setCurrentTime: (currenttime: number) => void;
    isMsgPlaying: boolean;
    setIsMsgPlaying: (isMsgPlaying: boolean) => void;
    thisMsgIsPlaying: boolean;
    setThisMsgIsPlaying: (thisMsgIsPlaying: boolean) => void;
    stopMsgPlaying: boolean;
}

let audio = new Audio();


export const SpeechOutput = ({ url, darkmode, playbackrate, currenttime, setCurrentTime ,isMsgPlaying, setIsMsgPlaying, thisMsgIsPlaying, setThisMsgIsPlaying, stopMsgPlaying }: Props) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [isPaused, setIsPaused] = useState(false);

    const startOrStopAudio = async () => {
        if (isPlaying) {
            //console.log("pausing audio");
            audio.pause();
            setIsPlaying(false);
            setIsMsgPlaying(false);
            setThisMsgIsPlaying(false);
            setIsPaused(true);
            setCurrentTime(audio.currentTime);

            return;
        }

        if (!url) {
            console.error("Speech output is not yet available.");
            return;
        }
        if (!isPaused) {
            //console.log("state is not paused");
            audio = new Audio(url);
        } else {
            audio = new Audio(url);
            audio.currentTime = currenttime;
            //console.log("setting pause state to false");
            setIsPaused(false);
        }

        if (playbackrate) {
            audio.playbackRate = parseFloat(playbackrate);
        }
        //audio.playbackRate = 1.25;
        //console.log("playing audio @ " + audio.playbackRate);
       
            await audio.play();
    
        // let r = await requestTTS(url);
        // if (r){
        //     audio = new Audio(r);
        //     await audio.play();
        // } else {
        //     console.error("Speech output is not yet available.");
        // return;
        // }

        audio.addEventListener("ended", () => {
            setIsPlaying(false);
            setIsMsgPlaying(false);
            setThisMsgIsPlaying(false);
            //console.log("done playing audio");
        });

        setIsPlaying(true);
        setIsMsgPlaying(true);
        setThisMsgIsPlaying(true);
    
    };

    const color = isPlaying ? "#329887" : darkmode ? "white" : "black";
    const isdisabled = () => {
         let x = true;
        //console.log("isMsgPlaying: " + isMsgPlaying + " thisMsgIsPlaying: " + thisMsgIsPlaying + " URL: " + url);
         if (!url) {
                x = true;
         } else{
            //console.log("isMsgPlaying: " + isMsgPlaying + " thisMsgIsPlaying: " + thisMsgIsPlaying);
            x = isMsgPlaying && !thisMsgIsPlaying;
         }
      
         return x;
    };
    useEffect(() => {
        if (currenttime==0 && isPaused) {
            setCurrentTime(0);
            //console.log("*current time is 0 and paused, setting isPaused to false");
            setIsPaused(false);
            setThisMsgIsPlaying(false);
            setIsPlaying(false);
            //setIsMsgPlaying(false);
        }
      

    }, [currenttime]);
    useEffect(() => {
        //console.log("*isPlaying: " + isPlaying +  "isMsgPlaying: " + isMsgPlaying + " thisMsgIsPlaying: " + thisMsgIsPlaying + " stopMsgPlaying: " + stopMsgPlaying);
        if (stopMsgPlaying && isPlaying) {
            //console.log("*clear msg playing, setting isPaused to true");
            
                //console.log("pausing audio");
                audio.pause();
                setIsPlaying(false);
                setIsMsgPlaying(false);
                setThisMsgIsPlaying(false);
                setIsPaused(true);
                setCurrentTime(audio.currentTime);

        }
    }, [stopMsgPlaying]);
    return (
        // <div>
        <IconButton
            style={{ color: color, backgroundColor: "transparent" }}
            iconProps={{ iconName: isPaused ? "CirclePause" : "Volume3" }}
            title="Speak answer"
            ariaLabel="Speak answer"
            onClick={() => startOrStopAudio()}
            disabled={isdisabled()}
        />
       
        // </div>
    );
};