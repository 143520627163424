import {useEffect,  Component}  from 'react';
import { initTranslate } from "../../google-translate/translate";
interface Props {
    darkmode: boolean;
}
export const GoogleTranslate = ({ darkmode }: Props) => {
    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
        const remove = initTranslate();

        return remove;
    }, []);
    return (
        <div id="google_translate_element" data-theme={darkmode? "dark" : ""}></div> 

    );
}